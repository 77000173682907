import React from 'react'

import styles from './about-company.module.scss'
import {Title} from "../title/title";
import info from "../../assets/icons/info.svg";


export const AboutCompany = (): JSX.Element => {
  return <div className={styles.root} id='about-company'>
    <div className={styles.content}>
      <Title img={info}>О компании</Title>
      <p><b>Secure Opinion</b> предоставляет услуги по разработке и тестированию программных продуктов на заказ, в том числе решений для детектирования и предотвращения кибератак.</p><br/>
      <p>Наша команда состоит из высококвалифицированных специалистов в различных направлениях IT, имеющих за плечами не один успешно реализованный проект.</p>
    </div>
  </div>
}
