import React from 'react'

import Input from 'react-phone-number-input/input'
import mail from '../../assets/icons/mail.svg'

import styles from './contacts.module.scss'
import {Button} from '../button/button'
import {Title} from "../title/title";

export const Contacts = (): JSX.Element => {
  const [form, setForm] = React.useState({'name': '', 'number': '', 'email': '', 'message': ''})
  const [err, setErr] = React.useState('')
  const [block, setBlock] = React.useState(false)

  const validate = () => {
    console.log(form)

    if (form.name && form.message) {
      if (form.number || form.email) {
        if (form.email && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(form.email)) {
          setErr('Введите корректный e-mail адрес.')
          return
        }
        if (form.number && !/^\+[1-9]\d{10,14}$/.test(form.number)) {
          setErr('Введите корректный номер телефона.')
          return
        }
      } else {
        setErr('Заполните номер телефона или e-mail адрес.')
        return
      }
    } else {
      setErr('Заполните ФИО и текст обращения.')
      return
    }

    setErr('')
    fetch(`https://${process.env.REACT_APP_BACKEND_ADDRESS}/feedback`, {
      method: 'POST',
      body: JSON.stringify(form)
    })
      .then(() => {setErr('Сообщение отправлено.'); setForm({'name': '', 'number': '', 'email': '', 'message': ''})})
      .catch(() => setErr('Ошибка сервера. Повторите попытку позже.'))
  }

  return <div className={styles.root} id='contacts'>
    <div className={styles.content}>
      <Title img={mail}>Контакты</Title>
      <div className={styles.info}>
        <div className={styles.map} onClick={() => window.open('https://www.google.com/maps/place/Amangeldi+Imanov+Street+19,+Astana+010000,+Kazakhstan', '_blank')}>
          <div className={styles.address}>г. Астана, ул. Амангельды Иманова 19</div>
        </div>
        <div className={styles.card_root}>
          <div className={styles.card_content_title}>
            <div className={styles.card_title}>Связаться с нами:</div>
          </div>
          <div className={styles.card_content}>
            <a href='mailto:titovda@secopin.ru' target='_blank'>Почта: titovda@secopin.ru</a>
            <a href='https://t.me/secopin' target='_blank'>Telegram: @secopin</a>
          </div>
        </div>
      </div>
      <div className={styles.form}>
        <div className={styles.title}>Написать нам:</div>
        <input className={styles.input} disabled={block} name='name' placeholder='Введите ФИО*' value={form.name}
               onChange={(event) => setForm({...form, 'name': event.target.value})}/>
        <Input className={styles.input} disabled={block} placeholder='Введите номер телефона' value={form.number}
               onChange={(value) => {
                 if (value === undefined) setForm({...form, 'number': ''})
                 else setForm({...form, 'number': String(value)})}
               }/>
        <div className={styles.or}>или</div>
        <input className={styles.input} disabled={block} name='email' placeholder='Введите email' value={form.email}
               onChange={(event) => setForm({...form, 'email': event.target.value})}/>
        <textarea className={styles.textarea} disabled={block} placeholder='Введите текст обращения*' value={form.message}
                  onChange={(event) => setForm({...form, 'message': event.target.value})}/>
        <div>{err}</div>
        {block && <div>Сообщение отправлено!</div>}
        {!block && <Button onClick={() => validate()}>Отправить</Button>}
      </div>
    </div>
  </div>
}
