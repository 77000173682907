import React from 'react'

import styles from './header.module.scss'

export const Header = (): JSX.Element => {
  const [menu, setMenu] = React.useState(false)
  const [select, setSelect] = React.useState(0)
  const [language, setLanguage] = React.useState(false)

  return <div className={styles.root}>
    <div className={styles.content}>
      <div className={styles.logo}/>
      <div className={styles.menu}>
        <div className={`${styles.items}${menu ? ` ${styles.open}` : ''}`}>
          <div onClick={() => {setMenu(false); setSelect(0); window.scrollTo({top: document.getElementById('about-company')!.offsetTop - 70, behavior: 'smooth'})}} className={`${styles.item}`}>О компании</div>
          <div onClick={() => {setMenu(false); setSelect(1); window.scrollTo({top: document.getElementById('software-development')!.offsetTop - 70, behavior: 'smooth'})}} className={`${styles.item}`}>Разработка ПО</div>
          <div onClick={() => {setMenu(false); setSelect(2); window.scrollTo({top: document.getElementById('ib-providing')!.offsetTop - 70, behavior: 'smooth'})}} className={`${styles.item}`}>Обеспечение ИБ</div>
          {/*<div onClick={() => {setMenu(false); setSelect(3); window.scrollTo({top: document.getElementById('cases')!.offsetTop - 70, behavior: 'smooth'})}} className={`${styles.item}`}>Кейсы</div>*/}
          <div onClick={() => {setMenu(false); setSelect(4); window.scrollTo({top: document.getElementById('contacts')!.offsetTop - 70, behavior: 'smooth'})}} className={`${styles.item}`}>Контакты</div>
        </div>
        {/*<div className={styles.language} onClick={() => setLanguage(!language)}>{language ? <div className={styles.en_icon}/> : <div className={styles.ru_icon}/>}{language ? 'ENG' : 'RUS'}</div>*/}
        <div className={styles.menu_button} onClick={() => setMenu(!menu)}>Меню<div className={styles.menu_icon}/></div>
      </div>
    </div>
  </div>
}
