import React from 'react'
import ReactDOM from 'react-dom'

import './index.scss'

import {Header} from './components/header/header'
import {Main} from './components/main/main'
import {Footer} from './components/footer/footer'
import {Contacts} from './components/contacts/contacts'
import {OurClients} from './components/our-clients/our-clients'
import {AboutCompany} from './components/about-company/about-company'
import {SoftwareDevelopment} from "./components/software-development/software-development";
import {IbProviding} from "./components/ib-providing/ib-providing";
import {Cases} from "./components/cases/cases";

ReactDOM.render(
	<React.StrictMode>
		<Header/>
		<Main/>
		<AboutCompany/>
		<SoftwareDevelopment/>
		<IbProviding/>
		<OurClients/>
		{/*<Cases/>*/}
		<Contacts/>
		<Footer>Copyright © Secure Opinion 2020 – 2024</Footer>
	</React.StrictMode>,
	document.getElementById('root')
)
