import React from 'react'

import styles from './title.module.scss'

interface propsTitle {
  children?: string
  img?: string
}

export const Title = (props: propsTitle): JSX.Element => {
  return <div className={styles.root}>
    <div className={styles.img} style={{backgroundImage: `url('${props.img}')`}}/>
    <div className={styles.text}>{props.children}</div>
  </div>
}
