import React from 'react'

import styles from './software-development.module.scss'
import {Title} from "../title/title";
import code from "../../assets/icons/code.svg";

export const SoftwareDevelopment = (): JSX.Element => {
  return <div className={styles.root} id='software-development'>
    <div className={styles.content}>
      <div className={styles.text}>
        <Title img={code}>Разработка ПО</Title>
        Мы ведем разработку с использованием самых разнообразных стеков технологий, актуальных стандартов и методологий. Это и опытная команда разработчиков позволяет нам браться за проекты любой сложности. От простого веб-сайта до масштабных Enterprise-систем. Наша цель - создание максимально удобных, безопасных и производительных решений.
      </div>
      <div className={styles.container}>
        <div className={styles.title}>Этапы разработки ПО</div>
        <div className={styles.shashlik}><div className={styles.img}/></div>
      </div>
    </div>
  </div>
}
