import React from 'react'

import styles from './main.module.scss'
import background from '../../assets/videos/background.mp4'
import poster from '../../assets/items/poster.png'

import {Button} from '../button/button'

export const Main = (): JSX.Element => {
  return <div className={styles.root}>
    <video className={styles.video} src={background} poster={poster} autoPlay loop muted playsInline/>
    <div className={styles.content}>
      <div className={styles.header}>IT-решения для вашего бизнеса</div>
      <div className={styles.main}>С нами безопасность доступнее, а разработка эффективнее. Обратившись к нам, Вы повысите Business Value Вашей компании и выведете её на новый уровень.</div>
        <Button onClick={() => window.scrollTo({top: document.getElementById('contacts')!.offsetTop - 70, behavior: 'smooth'})}>Связаться с нами</Button>
    </div>
  </div>
}
