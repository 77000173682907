import React from 'react'

import styles from './ib-providing.module.scss'
import {Button} from '../button/button'
import {Title} from "../title/title";
import shield from "../../assets/icons/shield.svg";

export const IbProviding = (): JSX.Element => {
  return <div className={styles.root} id='ib-providing'>
    <div className={styles.content}>
      <div className={styles.up}>
        <Title img={shield}>Обеспечение ИБ</Title>
        <div className={styles.text}>Наша команда проводит оценку безопасности инфраструктуры, аудит приложений, а также занимается интеграцией и аутсорсом средств защиты информации.<br/><br/>По результатам аудита формируется проект по модернизации и развитию инфраструктуры с использованием современных методов обеспечения информационной безопасности.</div>
        <div className={styles.btn}><Button onClick={() => window.open('/Secopin_IS_ru.pdf', '_blank')}>Ознакомиться с полным списком услуг</Button></div>
      </div>
      <div className={styles.container}>
        <div className={styles.title}>Этапы обеспечения ИБ</div>
        <div className={styles.shashlik}><div className={styles.img}/></div>
      </div>
    </div>
  </div>
}
