import React from 'react'

import styles from './our-clients.module.scss'
import {Title} from "../title/title";
import user from "../../assets/icons/user.svg";

export const OurClients = (): JSX.Element => {
  return <div className={styles.root}>
    <div className={styles.content}>
      <Title img={user}>Наши клиенты</Title>
    </div>
  </div>
}
