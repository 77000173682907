import React from 'react'

import styles from './button.module.scss'

interface propsButton {
  children?: string
  onClick?: () => void
}

export const Button = (props: propsButton): JSX.Element => {
  return <div className={styles.root} onClick={props.onClick}>{props.children}</div>
}
