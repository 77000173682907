import React from 'react'

import styles from './footer.module.scss'

interface propsFooter {
  children?: string
}

export const Footer = (props: propsFooter): JSX.Element => {
  return <div className={styles.root}>
    <div className={styles.content}>
      {props.children}
    </div>
  </div>
}
